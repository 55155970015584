import * as Types from "../../actions/actionTypes";

const initialState = {};

for (let i = 5; i < 11; i++) {
  initialState["chart" + i] = {
    loading: true,
    data: {
      chart_label: "",
      data: [],
      legends: [],
      chart_info: {
        chart_label: "",
        data: {},
      },
    },
    error: "",
  };
}

function amazonChartReducer(state = initialState, action) {
  const newState = { ...state };

  for (let i = 5; i < 11; i++) {
    const chartNumber = "chart" + i;

    if (action.type === Types.amazonChartActionTypes[`AMAZON_CHART_${i}_DATA_LOADING`]) {
      newState[chartNumber] = {
        ...state[chartNumber],
        loading: action.payload,
      };
      return newState;
    }
    if (action.type === Types.amazonChartActionTypes[`AMAZON_CHART_${i}_DATA_LOADED`]) {
      newState[chartNumber] = {
        ...state[chartNumber],
        data: action.payload,
        error: "",
      };
      return newState;
    }
    if (action.type === Types.amazonChartActionTypes[`AMAZON_CHART_${i}_DATA_LOAD_ERROR`]) {
      newState[chartNumber] = {
        ...state[chartNumber],
        error: action.payload,
      };
      return newState;
    }
  }

  if (action.type === Types.AMAZON_RESET_CHART_DATA) {
    return initialState;
  }

  return state;
}

export default amazonChartReducer;

